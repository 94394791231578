/**
 * Gets image dimensions in pixels
 * @param dataUrlBase64 of type string. Must have format: data:${contentType};base64,${base64Data}
 */

export async function getImageDimensions(
  dataUrlBase64: string
): Promise<{ width: number; height: number }> {
  if (!dataUrlBase64.startsWith("data:")) {
    throw new Error(
      "Invalid type for base64ToBlob input, must be a Data Url with Base 64 encoding"
    );
  }

  return await new Promise((resolve, reject) => {
    const image = new Image();

    image.addEventListener("load", () => {
      const width = image.naturalWidth;
      const height = image.naturalHeight;
      resolve({ width, height });
    });

    image.addEventListener("error", (error) => {
      reject(error);
    });

    image.src = dataUrlBase64;
  });
}

export function pixelsToInches(pixels: number, dpi = 72) {
  return pixels / dpi;
}

interface GetFittedDimensionsInInchesParams {
  imageWidthInPixels: number;
  imageHeightInPixels: number;
  fittedWidthInInches: number;
  fittedHeightInInches: number;
}
export function getFittedDimensionsInInches(params: GetFittedDimensionsInInchesParams) {
  const { imageWidthInPixels, imageHeightInPixels, fittedWidthInInches, fittedHeightInInches } =
    params;

  if (fittedWidthInInches <= 0 || fittedHeightInInches <= 0) {
    throw new Error("fittedWidthInInches and fittedHeightInInches must be greater than 0");
  }

  const imageWidthInInches = pixelsToInches(imageWidthInPixels);
  const imageHeightInInches = pixelsToInches(imageHeightInPixels);

  const widthRatio = imageWidthInInches / fittedWidthInInches;
  const heightRatio = imageHeightInInches / fittedHeightInInches;

  if (widthRatio > heightRatio) {
    return {
      scaledWidth: fittedWidthInInches,
      scaledHeight: imageHeightInInches / widthRatio,
    };
  }

  return {
    scaledWidth: imageWidthInInches / heightRatio,
    scaledHeight: fittedHeightInInches,
  };
}
