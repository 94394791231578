import { fileTypeFromBuffer } from "file-type";

import {
  ACCEPTABLE_UPLOAD_FILE_CONTENT_TYPES,
  ACCEPTABLE_UPLOAD_IMAGE_CONTENT_TYPES,
} from "./constants";
import {
  type AcceptableUploadFileContentTypes,
  type AcceptableUploadImageContentTypes,
  type FileType,
} from "./types";

export async function blobToBase64(blob: Blob): Promise<string> {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener("error", (error) => {
      reject(error);
    });

    reader.onloadend = () => {
      const { result } = reader;
      if (typeof result === "string") {
        resolve(result);
      } else {
        reject(new Error("Error while converting blob to base 64, reader.result is not string"));
      }
    };

    reader.readAsDataURL(blob);
  });
}

/**
 * Converts Data Url with Base 64 encoding of a file to a blob
 * @param dataUrlBase64 of type string. Must have format: data:${contentType};base64,${base64Data}
 * This is not an API fetch call, but a convenient use of fetch to convert to a blob
 */
export async function base64ToBlob(dataUrlBase64: string): Promise<Blob> {
  if (!dataUrlBase64.startsWith("data:")) {
    throw new Error(
      "Invalid type for base64ToBlob input, must be a Data Url with Base 64 encoding"
    );
  }

  const response = await fetch(dataUrlBase64);
  return await response.blob();
}

export async function getFileTypeFromBlob(blob: Blob): Promise<FileType> {
  const arrayBuffer = await blob.arrayBuffer();

  const result = await fileTypeFromBuffer(arrayBuffer);

  if (!result) {
    throw new Error("Could not get file type from blob");
  }

  const { ext: extension, mime: contentType } = result;
  return { extension, contentType };
}

export function isAcceptableImageUploadContentType(contentType: string): boolean {
  return ACCEPTABLE_UPLOAD_IMAGE_CONTENT_TYPES.includes(
    contentType as AcceptableUploadImageContentTypes
  );
}

export function isAcceptablePdfUploadContentType(contentType: string): boolean {
  return contentType === "application/pdf";
}

export function isAcceptableFileUploadContentType(contentType: string): boolean {
  return ACCEPTABLE_UPLOAD_FILE_CONTENT_TYPES.includes(
    contentType as AcceptableUploadFileContentTypes
  );
}
