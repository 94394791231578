/** Returns a Promise which will resolve with a Blob once the fileInputElement has either selected a file or canceled */
export async function waitForFileInputPick(
  fileInputElement: HTMLInputElement
): Promise<Blob | undefined> {
  let resolveImage: (value: Blob | undefined) => void;

  async function onFileChange(event: Event) {
    event.preventDefault();
    const target = event.target as HTMLInputElement;
    if (target.files) {
      resolveImage(target.files ? target.files[0] : undefined);
    }

    target.value = "";
  }

  function onFileCancel(event: Event) {
    event.preventDefault();
    if (resolveImage) {
      resolveImage(undefined);
    }

    fileInputElement.removeEventListener("change", onFileChange);
  }

  return await new Promise<Blob | undefined>((resolve) => {
    resolveImage = resolve;
    fileInputElement.addEventListener("change", onFileChange, { once: true });
    fileInputElement.addEventListener("cancel", onFileCancel, { once: true });
  }).finally(() => {
    fileInputElement.removeEventListener("change", onFileChange);
    fileInputElement.removeEventListener("cancel", onFileCancel);
  });
}

/** Opens the devices camera (if applicable) and resolves with the captured photo. */
export async function takePhoto(fileInputElement: HTMLInputElement): Promise<Blob | undefined> {
  fileInputElement.setAttribute("capture", "environment");
  const filePromise = waitForFileInputPick(fileInputElement);
  fileInputElement.click();
  return await filePromise;
}

/** Opens the device's default prompt to select an image and resolves with the select image. */
export async function chooseFromLibrary(
  fileInputElement: HTMLInputElement
): Promise<Blob | undefined> {
  fileInputElement.removeAttribute("capture");
  const filePromise = waitForFileInputPick(fileInputElement);
  fileInputElement.click();
  return await filePromise;
}
