export const ACCEPTABLE_UPLOAD_IMAGE_CONTENT_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
] as const;

export const ACCEPTABLE_UPLOAD_FILE_CONTENT_TYPES = [
  ...ACCEPTABLE_UPLOAD_IMAGE_CONTENT_TYPES,
  "application/pdf",
] as const;
